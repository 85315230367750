// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";

//import FormattedDate from "global/utilities/Date";
//import Price from "global/utilities/Price";
import PriceRange from "global/utilities/PriceRange";
import { formatInteger, formatPercentage } from "global/utilities/format";
import LinkToMetagameArchetype from "common/links/LinkToMetagameArchetype";
import TableCellItem from "common/TableCellItem";
import ColorIdentities from "common/ColorIdentities";
import ColorIdentity from "common/ColorIdentity";
import PopularityDirectionIndicator from "common/PopularityDirectionIndicator";
//import LinkToDeckSearchResults from "common/links/LinkToDeckSearchResults";

import { getLocalStorage } from "global/utilities/browserStorage";
import { metagameFormatPeriodSchema, metagameArchetypeSchema } from "apiSchemas";
//import { getFormatDisplayName } from "common/resources/formatNames";

// -------------------------------------------------------------------------------------------------
// Table that shows list of linked tournament summaries.
// REVIEW
export default function ArchetypesTable({ formatPeriod, displayOptions, hideHistorical }) {
	const tableNotStriped = displayOptions && displayOptions.showTableNotStriped;
	const tableBordered = displayOptions && displayOptions.showTableBordered;
	const archetypes = !hideHistorical || !formatPeriod.archetypes ? formatPeriod.archetypes : formatPeriod.archetypes.filter(archetype => archetype.popularity > 0);
	const isDebugModeEnabled = getLocalStorage("isDebugModeEnabled") == "true";

	return (
		<Table striped={!tableNotStriped} bordered={tableBordered} size="sm">
			<thead>
				<tr>
					<th>Archetype</th>
					<th className="text-center">Popularity</th>
					{isDebugModeEnabled && <th className="text-center">Performance</th>}
					<th className="text-end d-none d-sm-table-cell">Decks</th>
					<th className="text-end d-none d-sm-table-cell">Price Range</th>
				</tr>
			</thead>

			<tbody>
				{archetypes && archetypes.length > 0 && archetypes.map(archetype =>
					<ArchetypesTableRow
						key={archetype.archetypeId}
						archetype={archetype} />
				)}
				{(!archetypes || archetypes.length === 0) &&
					<tr>
						<td className="text-muted text-center" colSpan={4}>
							No archetypes found
						</td>
					</tr>
				}
			</tbody>
		</Table>
	);
}

ArchetypesTable.propTypes = {
	formatPeriod: PropTypes.shape(metagameFormatPeriodSchema).isRequired,
	displayOptions: PropTypes.object,
	hideHistorical: PropTypes.bool
};

// -------------------------------------------------------------------------------------------------
function ArchetypesTableRow({ archetype }) {
	const isDebugModeEnabled = getLocalStorage("isDebugModeEnabled") == "true";

	return (
		<>
			<tr>
				<td style={{ maxWidth: "150px" }}>
					<TableCellItem>
						<LinkToMetagameArchetype archetypeId={archetype.archetypeId}>
							{archetype.archetypeName ? archetype.archetypeName : "<unknown archetype>"}
						</LinkToMetagameArchetype>
					</TableCellItem>
					{" "}
					{/* FUTURE: Currently doesn't show ellipse properly if color identity is inside TableCellItem */}
					{/*{<ColorIdentities identities={archetype.colorIdentities} />}*/}
					{archetype.variations.length === 1 ? <ColorIdentities identities={archetype.colorIdentities} /> : null}
					{/*{archetype.variations.length > 1 ? <Badge className="ms-1" bg="info" text="light">{archetype.variations.length}</Badge> : null}*/}
				</td>

				<td className="text-center text-nowrap" style={{ maxWidth: "50px" }}>
					{archetype.popularity >= 0.005 ?
						<>
							{formatPercentage(archetype.popularity)}
							{archetype.popularityDirection ?
								<>
									{" "}
									<PopularityDirectionIndicator
										value={archetype.popularityDirection} />
								</> : null
							}
						</> : null
					}
				</td>

				{isDebugModeEnabled &&
					<td className="text-center text-nowrap d-none d-sm-table-cell" style={{ maxWidth: "40px" }}>
						{archetype.recentRecord} {" "}
						({formatPercentage(archetype.recentPerformance)})
					</td>
				}

				<td className="text-end text-nowrap d-none d-sm-table-cell" style={{ maxWidth: "40px" }}>
					{formatInteger(archetype.numberOfDecks)}
				</td>

				<td className="text-end d-none d-sm-table-cell">
					<PriceRange lowerValue={archetype.lowestPrice} upperValue={archetype.highestPrice} decimalPlaces={0} />
				</td>
			</tr>

			{archetype.variations.length > 1 ? archetype.variations.map(variation =>
				<tr key={variation.archetypeVariationId}>
					<td style={{ maxWidth: "150px" }} className="ps-4">
						<span>&bull;</span>
						{" "}
						<TableCellItem>
							<LinkToMetagameArchetype archetypeId={archetype.archetypeId} archetypeVariationId={variation.archetypeVariationId}>
								{variation.archetypeVariationName ? variation.archetypeVariationName : "<unknown variation>"}
							</LinkToMetagameArchetype>
						</TableCellItem>
						{" "}
						{/* FUTURE: Currently doesn't show ellipse properly if color identity is inside TableCellItem */}
						<ColorIdentity identity={variation.colorIdentity} />
					</td>

					<td>
					</td>

					{isDebugModeEnabled &&
						<td className="text-center text-nowrap d-none d-sm-table-cell" style={{ maxWidth: "40px" }}>
						</td>
					}
					<td className="text-end text-nowrap d-none d-sm-table-cell" style={{ maxWidth: "50px" }}>
						{formatInteger(variation.numberOfDecks)}
					</td>

					<td className="text-end d-none d-sm-table-cell">
						<PriceRange lowerValue={variation.lowestPrice} upperValue={variation.highestPrice} decimalPlaces={0} />
					</td>
				</tr>) : null
			}
		</>
	);
}

ArchetypesTableRow.propTypes = {
	archetype: PropTypes.shape(metagameArchetypeSchema).isRequired
};